import React from "react";
import Content from '../views/Fleet/Why';

export default ({location}) => (
  <Content location={location} seo={{
    title: "Hvorfor du skal vælge et Fleet flådestyringssystem.",
    lang: 'da-DK',
    description: "Alle fordele ved vores flådestyringssystem. Det gøre det lettere at spore, administrere, monitorere og vedligeholde biler.",
    meta: [{
        name: 'keywords',
        content: 'Flådestyringssystem'
      }]
  }} />
);